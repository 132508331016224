import { CloseButton } from "shared/ui/modals";
import styled from "styled-components";
import { Dialog } from "@mui/material";
import { ListField } from "shared/lib/form";
import { mediaDesktop, mediaPhone, useMedia } from "shared/ui/styles/media";

import {
  FtInputField,
  InputField,
  PriceField,
  TaxField,
} from "shared/ui/ecosystems/forms";
import { ButtonGroupField } from "shared/ui/ecosystems/forms/organisms/button-group-field";

import {
  $parkingModalOpened,
  $parkingSpaceId,
  parkingModalActions,
} from "@manager-app/features/manage-docks/model/parking-dialog.model";
import { createGate, useGate, useStore, useUnit } from "effector-react";
import { editDockForm } from "@manager-app/features/manage-docks/model/edit-dock-form.model";
import { ListTemplate } from "../list-template";

interface Props {}

export function ParkingDialog(props: Props): JSX.Element {
  const { sections } = useStore(editDockForm.$values);
  const [parkingModalOpened, parkingId] = useUnit([
    $parkingModalOpened,
    $parkingSpaceId,
  ]);

  if (!parkingId) {
    return <></>;
  }

  const { sectionIndex, spaceIndex } = parkingId;

  return (
    <ModalForm open={parkingModalOpened} maxWidth={"lg"}>
      <CloseButton onClick={() => parkingModalActions.close()} />
      <>
        <ListField name={`sections.${sectionIndex}.spaces`}>
          {({ map }) =>
            map(({ index }) => {
              if (index !== spaceIndex) {
                return <></>;
              }
              return (
                <div key={index}>
                  <SubBlock>
                    <Subtitle>Basic Info</Subtitle>
                    <FieldsGroup>
                      <InputField
                        name="name"
                        label="Enter docking space name *"
                      />
                    </FieldsGroup>
                  </SubBlock>

                  <SubBlock>
                    <Subtitle>Type of Docking *</Subtitle>
                    <ButtonGroupField
                      name="parkingType"
                      options={[
                        {
                          label: "Docking Along",
                          value: "along",
                        },
                        {
                          label: "Docking Across",
                          value: "across",
                        },
                      ]}
                    ></ButtonGroupField>

                    <HalfGroup>
                      <FtInputField name="length" label="Enter Length *" />
                      <FtInputField name="width" label="Enter Beam *" />
                    </HalfGroup>
                  </SubBlock>

                  <SubBlock>
                    <ItemHeader>
                      <Subtitle>Price Settings</Subtitle>
                    </ItemHeader>

                    <HalfGroup>
                      <PriceField
                        name="defaultPrice"
                        label="Default Price *"
                        min={0}
                      />
                      <TaxField
                        name="taxRate"
                        label="Tax Rate *"
                        maxLength={5}
                      />
                    </HalfGroup>
                  </SubBlock>

                  <ListField
                    name={`sections.${sectionIndex}.spaces.${spaceIndex}.prices`}
                  >
                    {({ map, remove, push }) => (
                      <ListTemplate
                        map={map}
                        onDelete={remove}
                        onAdd={() => push({})}
                        addLabel="Add Additional Price"
                        deleteLabel="Delete Price"
                        getItemTitle={(_, index) => `Price ${index + 1} *`}
                        renderItem={() => (
                          <ThirdGroup>
                            <FtInputField
                              name="dimensionFrom"
                              label="From"
                              min={0}
                            />
                            <FtInputField
                              name="dimensionTo"
                              label="To"
                              min={0}
                            />
                            <PriceField name="price" label="Price" min={0} />
                          </ThirdGroup>
                        )}
                      />
                    )}
                  </ListField>
                </div>
              );
            })
          }
        </ListField>
      </>
      {/* <Actions>{props.renderActions?.()}</Actions> */}
    </ModalForm>
  );
}

const ItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const Subtitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
`;

const SubBlock = styled.div`
  margin-bottom: 24px;
`;

const HalfGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  ${mediaPhone} {
    grid-template-columns: 1fr;
  }
`;

const ThirdGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;

  ${mediaPhone} {
    grid-template-columns: 1fr;
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  font-family: "Kanit", sans-serif;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
`;

const FieldsGroup = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr;

  ${mediaPhone} {
    grid-template-columns: 1fr;
  }

  ${HalfGroup} {
    grid-column: 1 / 2;
    ${mediaPhone} {
      grid-column: 1;
    }
  }

  ${ThirdGroup} {
    grid-column: 1 / 3;
    ${mediaPhone} {
      grid-column: 1;
    }
  }
`;

const ModalForm = styled(Dialog)`
  .MuiDialog-paper {
    ${mediaDesktop} {
      width: 580px;
      border-radius: 32px;
      padding: 24px;
    }

    ${mediaPhone} {
      background: #f5fafa;
      padding: 16px;
    }
  }
  position: relative;

  ${CloseButton} {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  ${Title} {
    margin-bottom: 16px;
  }

  ${mediaPhone} {
    ${Title} {
      margin-bottom: 12px;
    }
  }
`;
