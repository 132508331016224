import React from "react";
import styled from "styled-components";

import { mediaPhone } from "shared/ui/styles/media";
import {
  CheckBoxField,
  DateField,
  DistanceField,
  FloatInputField,
  FtInputField,
  InputField,
  PhoneField,
  PriceField,
  RichTextEditorField,
  SelectField,
  StateField,
  SubmitButton,
  TaxField,
  TextAreaField,
  TypePriceSelectField,
} from "shared/ui/ecosystems/forms";
import { ListField, useField } from "shared/lib/form";
import { RestaurantTypeField } from "@manager-app/entities/restaurants";

import { AddonField } from "@manager-app/entities/addons";

import { City } from "shared/api/types";

import { DayOperationalHours } from "./operatoins-hours-field";
import { ListTemplate } from "./list-template";
import { ImageField } from "./image-field/image-field";
import { SectionForm } from "./dock-section/section-form";

export function FormFields(props: {
  submitText: string;
  isSubmitting: boolean;
  cities: City[];
}): JSX.Element {
  const {
    input: { value: addons },
  } = useField({ name: "addons" });

  return (
    <DockFormRoot>
      <DockFormField>
        <FormBlock title="Image *">
          <ImageField />
        </FormBlock>

        <FormBlock title="Main info">
          <FieldsGroup>
            <DockNameField name="name" label="Enter dock name *" />
            <PhoneField name="phone" label="Enter phone number *" />
            <InputField name="email" label="Enter email * " />
            <InputField name="address" label="Enter address *" />
            <SelectField
              name="cityObject"
              label="Enter city *"
              options={props.cities.map((city) => ({
                label: city.name,
                value: city["@id"],
              }))}
            />
            <StateField name="state" label="Select state *" />
            <InputField name="zipCode" label="Enter zip code *" />
            <FtInputField name="length" label="Enter length *" />
            <FtInputField name="width" label="Enter width *" />
          </FieldsGroup>
        </FormBlock>

        <FormBlock title="Dock description">
          <DockDescription>
            <RichTextEditorField name="description" className="editor-field" />
          </DockDescription>
        </FormBlock>

        <FormBlock title="Operational hours *">
          <DayOperationalHours />
        </FormBlock>

        <FormBlock title="">
          <SectionForm />
        </FormBlock>

        <FormBlock title="Out of season dates">
          <FieldsGroup>
            <DateField name="outOfSeasonTimeFrom" label="From" />
            <DateField name="outOfSeasonTimeTo" label="To" />
          </FieldsGroup>
        </FormBlock>

        <FormBlock title="Restaurants">
          <ListField name="restaurants">
            {({ map, push, remove }) => (
              <ListTemplate
                map={map}
                onDelete={remove}
                onAdd={() => push({})}
                addLabel="Add a restaurant"
                getItemTitle={(_, index) => `Restaurant ${index + 1}`}
                renderItem={() => (
                  <FieldsGroup>
                    <RestaurantTypeField name="type" label="Select type" />
                    <InputField name="name" label="Restaurant name" />
                    <InputField name="url" label="Link to the restaurant" />
                    <DistanceField name="distance" label="Distance" />
                  </FieldsGroup>
                )}
              />
            )}
          </ListField>
        </FormBlock>
        <FormBlock title="Add-ons">
          <ListField name="addons">
            {({ map, push, remove }) => (
              <ListTemplate
                map={map}
                onDelete={remove}
                onAdd={() => push({})}
                addLabel="Add add-on"
                getItemTitle={(_, index) => `Add-on ${index + 1}`}
                renderItem={() => <AddonFields selected={addons} />}
              />
            )}
          </ListField>
        </FormBlock>
        <FormBlock title="FAQ">
          <ListField name="faqs">
            {({ map, push, remove }) => (
              <>
                <ListTemplate
                  map={map}
                  onDelete={remove}
                  addLabel=""
                  onAdd={() => push({})}
                  hideAddButton
                  getItemTitle={(_, index) => `Section ${index + 1}`}
                  renderItem={() => (
                    <FaqFieldsGroups>
                      <QuestionField
                        name="question"
                        label="Enter the question *"
                      />
                      <AnswerField name="answer" label="Provide the answer *" />
                    </FaqFieldsGroups>
                  )}
                />
                <CustomAddButton onClick={() => push({})}>
                  Add Section
                </CustomAddButton>
              </>
            )}
          </ListField>
        </FormBlock>
      </DockFormField>

      <CreateDockButton isSubmitting={props.isSubmitting}>
        {props.submitText}
      </CreateDockButton>
    </DockFormRoot>
  );
}

function AddonFields(props: { selected: string[] }): JSX.Element {
  const { input } = useField({ name: "taxable" });
  const isTaxable = input.value;
  return (
    <FieldsGroup>
      <AddonField
        name="addon"
        label="Select add-on"
        selected={props.selected as any}
      />
      <AddOnDescription>
        <TextAreaField
          name="description"
          label="Add-on description"
          minRows={3}
          maxRows={3}
        />
      </AddOnDescription>
      <PriceField name="price" label="Price *" />
      <TypePriceSelectField name="priceType" label="Price type" />
      <TaxableField name="taxable">This add-on is subject to tax</TaxableField>
      {isTaxable && (
        <>
          <FloatInputField name="tax" label="Tax" />
          <TypePriceSelectField name="taxType" label="Tax type" />
        </>
      )}
    </FieldsGroup>
  );
}

const DockDescription = styled.div`
  .editor-field {
    width: 100%;
  }

  .editor {
    min-height: 200px;
  }
`;

const AddOnDescription = styled.div`
  display: flex;
  > .MuiFormControl-root {
    width: 100%;
  }
`;

const DockNameField = styled(InputField)``;
const TaxableField = styled(CheckBoxField)``;
const QuestionField = styled(InputField)``;
const AnswerField = styled(TextAreaField)``;

const FaqFieldsGroups = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;

  ${QuestionField} {
    max-width: 340px;
  }

  ${AnswerField} {
    max-width: 100%;
  }
`;

const FieldsGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  ${mediaPhone} {
    grid-template-columns: 1fr;
  }

  ${AddOnDescription} {
    grid-column: 1 / 3;
    ${mediaPhone} {
      grid-column: 1;
    }
  }

  ${TaxableField} {
    grid-column: 1 / 3;
    ${mediaPhone} {
      grid-column: 1;
    }
  }

  ${DockNameField} {
    grid-column: 1 / 3;
    ${mediaPhone} {
      grid-column: 1;
    }
  }
`;

const CustomAddButton = styled.div.attrs({
  role: "button",
})`
  line-height: 26px;
  cursor: pointer;
  width: 200px;
  height: 38px;
  border-width: 0.5px;
  border-color: #2d4fa1;
  color: #2d4fa1;
  border-style: solid;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;

function FormBlock(props: {
  title: string;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <FormBlockRoot>
      <FormBlockTitle>{props.title}</FormBlockTitle>
      <FormBlockContent>{props.children}</FormBlockContent>
    </FormBlockRoot>
  );
}

const FormBlockTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #0a1128;
`;

const FormBlockContent = styled.div``;

const FormBlockRoot = styled.div`
  max-width: 1280px;

  ${FormBlockTitle} {
    margin-bottom: 12px;
  }
`;

const DefaultPrice = styled.div``;

const PriceVariant = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;

  ${mediaPhone} {
    grid-template-columns: 1fr;
  }
`;

const DockFormField = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
`;

const CreateDockButton = styled(SubmitButton)``;

const DockFormRoot = styled.div`
  display: flex;
  flex-direction: column;

  ${DockFormField} {
    margin-bottom: 32px;
  }

  ${DefaultPrice} {
    margin-bottom: 24px;
  }

  ${CreateDockButton} {
    width: 322px;
    align-self: center;
  }
`;
