import styled from "styled-components";
import {
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup as MaterialRadioGroup,
  Button,
  useRadioGroup,
  FormControlLabelProps,
} from "@mui/material";

export interface ButtonGroupProps<T extends string | number> {
  label?: string;
  className?: string;
  options: { label: string | JSX.Element; value: T }[];
  value: T;
  onChange(value: T): void;
  defaultValue?: T;
}

function ButtonGroupComponent<T extends string | number>(
  props: ButtonGroupProps<T>
): JSX.Element {
  const StyledLabel = styled((props) => <FormControlLabel {...props} />)(
    ({ _, checked }) => ({
      marginLeft: "auto !important",
      marginRight: "auto !important",
      marginBottom: "24px",
      marginTop: "24px",
      ".MuiFormControlLabel-label": checked
        ? {
            width: "200px",
            height: "38px",
            borderWidth: "0.5px",
            borderColor: "#2d4fa1",
            color: "#2d4fa1",
            borderStyle: "solid",
            borderRadius: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "14px",
          }
        : {
            width: "200px",
            height: "38px",
            borderWidth: "0.5px",
            borderColor: "black",
            borderStyle: "solid",
            borderRadius: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "14px",
          },
    })
  );

  function ButtonFormControlLabel(props: FormControlLabelProps) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    return <StyledLabel checked={checked} {...props} />;
  }

  return (
    <RootRadioGroup className={props.className}>
      <InputLabel>{props.label}</InputLabel>
      <MaterialRadioGroup
        {...props}
        sx={{ display: "flex", flexDirection: "row", gap: "12px" }}
        onChange={(event) => props.onChange(event.target.value as T)}
      >
        {props.options.map((option) => (
          <ButtonFormControlLabel
            key={option.value}
            label={option.label}
            value={option.value}
            control={<HiddenRadio />}
          />
        ))}
      </MaterialRadioGroup>
    </RootRadioGroup>
  );
}

const RootRadioGroup = styled.div`
  display: flex;
`;
const HiddenRadio = styled(Radio)`
  &.MuiRadio-root {
    display: none;
  }
`;

export const ButtonGroup = styled(ButtonGroupComponent)``;
