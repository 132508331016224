import styled from "styled-components";

import { stringToFixed } from "shared/lib/string-to-fixed";

import { ReservationPrice } from "../api";

export function ReservationCost(props: {
  priceInfo: ReservationPrice | null;
}): JSX.Element {
  const beforeTaxAddons = props.priceInfo?.addons.filter(
    (addon) => addon.addonTax !== null
  );
  const afterTaxAddons = props.priceInfo?.addons.filter(
    (addon) => addon.addonTax === null
  );

  return (
    <Root>
      {props.priceInfo?.dockSpacePrice && (
        <PriceBlock>
          <PriceLine>
            <PriceLabel>Reservation price:</PriceLabel>
            <Price>${stringToFixed(props.priceInfo.dockSpacePrice)}</Price>
          </PriceLine>

          <PriceLine>
            <PriceLabel>
              Tax
              {props.priceInfo?.taxRate && (
                <PriceDetail>
                  (Mooring Tax {stringToFixed(props.priceInfo.taxRate)}%)
                </PriceDetail>
              )}
              :
            </PriceLabel>
            <Price>${stringToFixed(props.priceInfo.tax)}</Price>
          </PriceLine>
        </PriceBlock>
      )}
      {beforeTaxAddons?.length > 0 &&
        beforeTaxAddons.map((addon) => (
          <PriceBlock>
            <PriceLine>
              <PriceLabel>{addon.addonName}</PriceLabel>
              <Price>${stringToFixed(addon.price)}</Price>
            </PriceLine>
            <PriceLine>
              <PriceLabel>
                Tax
                {addon.addonTaxType === "percent" && (
                  <PriceDetail>
                    (addon tax {stringToFixed(addon.addonTax)}%)
                  </PriceDetail>
                )}
                :
              </PriceLabel>
              <Price>${stringToFixed(addon.tax)}</Price>
            </PriceLine>
          </PriceBlock>
        ))}

      {afterTaxAddons?.length > 0 &&
        afterTaxAddons.map((addon) => (
          <PriceLine>
            <PriceLabel>{addon.addonName}</PriceLabel>
            <Price>${stringToFixed(addon.price)}</Price>
          </PriceLine>
        ))}

      {props.priceInfo?.bookingFee && (
        <PriceLine>
          <PriceLabel>
            Booking fee
            {props.priceInfo.companyBookingFeeType === "percent" ? (
              <PriceDetail>
                ({stringToFixed(props.priceInfo.companyBookingFee)}%)
              </PriceDetail>
            ) : null}
            :
          </PriceLabel>
          <Price>${stringToFixed(props.priceInfo.bookingFee)}</Price>
        </PriceLine>
      )}

      {props.priceInfo?.charterFee && (
        <PriceLine>
          <PriceLabel>Charter fee:</PriceLabel>
          <Price>${stringToFixed(props.priceInfo.charterFee)}</Price>
        </PriceLine>
      )}

      {props.priceInfo?.totalPrice && (
        <TotalPriceLine>
          <TotalPriceLabel>Total amount:</TotalPriceLabel>
          <TotalPrice>${stringToFixed(props.priceInfo.totalPrice)}</TotalPrice>
        </TotalPriceLine>
      )}
    </Root>
  );
}

const PriceLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TotalPriceLabel = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #0a1128;
`;

const TotalPrice = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  color: #5e88ef;
`;

const TotalPriceLine = styled(PriceLine)`
  margin-top: 15px;
`;

const PriceLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  font-weight: 500;
  font-size: 14px;
`;

const PriceDetail = styled(PriceLabel)`
  color: #3bb7b6;
`;

const Price = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

const PriceBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  & > * {
    &:not(:first-child) {
      margin-left: 6px;
    }
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
