import { createEvent, createStore, sample } from "effector";
import { useStore } from "effector-react";
import { createOpenAbleState } from "shared/lib/effector-openable-state";

import { editDockForm } from "./edit-dock-form.model";

export const [$parkingModalOpened, parkingModalActions] = createOpenAbleState();
export const $parkingSpaceId = createStore<{
  sectionIndex: number;
  spaceIndex: number;
} | null>(null);

export const spaceEditingStarted = createEvent<{
  sectionIndex: number;
  spaceIndex: number;
}>();
export const spaceEditingClose = createEvent();

sample({
  clock: spaceEditingStarted,
  fn: (parkingId) => {
    return parkingId;
  },
  target: [parkingModalActions.open, $parkingSpaceId],
});
