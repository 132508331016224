import { array, boolean, InferType, number, object, string } from "yup";

import { keyValueObject } from "shared/lib/form";
import { dayjs } from "shared/lib/dayjs-ext/dayjs-ext";
import {
  phoneValidationError,
  requiredFieldValidationError,
} from "shared/config/error-text";

const length = number()
  .transform((value) => (value === "" ? null : value))
  .nullable()
  .typeError(" ")
  .required(requiredFieldValidationError)
  .min(0, " ");

const price = number()
  .transform((value) => parseFloat(value))
  .nullable()
  .typeError(requiredFieldValidationError)
  .required(" ");

export const dockFormSchema = object({
  image: string().required("Please, select another picture."),
  name: string().required(requiredFieldValidationError),
  description: string(),
  address: string().required(requiredFieldValidationError),
  state: string().required(requiredFieldValidationError),
  phone: string().phone("US", phoneValidationError).min(3),
  email: string().email(" ").required(requiredFieldValidationError),
  length: string().required(requiredFieldValidationError),
  width: string().required(requiredFieldValidationError).nullable(),
  zipCode: string().required(requiredFieldValidationError),
  days: keyValueObject({
    from: string()
      .required(requiredFieldValidationError)
      .nullable()
      .test(
        "Time from check",
        "Closing time must be later than opening time.",
        (value, ctx) => {
          const toMidnight =
            dayjs(ctx.parent.to).hour() === 0 &&
            dayjs(ctx.parent.to).minute() === 0;

          if (toMidnight) {
            return true;
          }

          return dayjs(value).isBefore(dayjs(ctx.parent.to), "hour");
        }
      ),
    to: string()
      .required(requiredFieldValidationError)
      .nullable()
      .test("Time to check", " ", (value, ctx) => {
        const toMidnight =
          dayjs(ctx.parent.to).hour() === 0 &&
          dayjs(ctx.parent.to).minute() === 0;

        if (toMidnight) {
          return true;
        }

        return dayjs(value).isAfter(dayjs(ctx.parent.from), "hour");
      }),
  }),
  outOfSeasonTimeFrom: string().nullable().optional(),
  outOfSeasonTimeTo: string().nullable().optional(),
  sections: array().of(
    object({
      name: string().required(requiredFieldValidationError),
      spaces: array().of(
        object({
          name: string().required(requiredFieldValidationError),
          parkingType: string().required(requiredFieldValidationError),
          length: number().required(requiredFieldValidationError),
          width: number().required(requiredFieldValidationError),
          defaultPrice: string().required(requiredFieldValidationError),
          taxRate: string().required(requiredFieldValidationError),
        }).required()
      ),
    }).required()
  ),
});

export type DockFormData = InferType<typeof dockFormSchema>;
