import * as typed from "typed-contracts";

import { PostDockCollection, getDockItemOk } from "shared/api";

import {
  convertHoursFromArrayToMap,
  convertHoursMapToArray,
  FormHours,
} from "entities/docks/lib/operational-hours";

import { convertDateToApi } from "../../../../shared/lib/dayjs-ext";

export type DockApiInput = PostDockCollection["body"] & {
  width: string;
  prices?: {
    lengthFrom?: string;
    lengthTo?: string;
    price?: number;
  }[];
  addons: {
    addon: string;
    description?: string;
    price: string;
    priceType: string;
    tax?: string | null;
    taxType?: string | null;
  }[];
};

export interface DockFaq {
  ["@id"]: string;
  id: number;
  question: string;
  answer: string;
  sorder: number;
}

export type DockFromApi = typed.Get<typeof getDockItemOk> & {
  taxRate: number;
  defaultPrice: number;
  width: number;
  addons: Array<{
    addon: string;
    description: string;
    price: string;
    priceType: string;
    tax?: string | null;
    taxType?: string | null;
  }>;
  outOfSeasonTimeFrom: string;
  outOfSeasonTimeTo: string;
  sections: {
    name: string;
    ["@id"]: string;
    id: number;
    image: {
      ["@id"]: string;
      id: number;
      url: string;
    };
    spaces: {
      ["@id"]: string;
      id: number;
      name: string;
      parkingType: string;
      length: number;
      width: number;
      defaultPrice: string;
      taxRate: string;
      prices: {
        dimensionFrom: number;
        dimensionTo: number;
        price: number;
      }[];
    }[];
  }[];
  faqs: DockFaq[];
};

export interface DockSpace {
  ["@id"]: string;
  id: number;
  name: string;
  parkingType: string;
  length: number;
  width: number;
  defaultPrice: string;
  taxRate: string;
  prices: {
    dimensionFrom: number;
    dimensionTo: number;
    price: number;
  }[];
}

export interface DockForm {
  image: string;
  name: string;
  description: string;
  address: string;
  city: string;
  cityObject: string;
  state: string;
  phone: string;
  email: string;
  length: number | null;
  width: number | null;
  zipCode: string;
  days: FormHours;
  outOfSeasonTimeFrom?: string | null;
  outOfSeasonTimeTo?: string | null;
  restaurants?: Array<{
    type?: string;
    name?: string;
    url?: string;
    distance?: number | null;
  }>;
  addons?: Array<{
    addon?: string;
    description?: string;
    price?: string;
    priceType?: string;
    taxable?: boolean;
    tax?: string | null;
    taxType?: string | null;
  }>;
  sections: {
    name: string;
    id: number;
    ["@id"]: string;
    image: string;
    spaces: DockSpace[];
  }[];
  faqs: DockFaq[];
}
export interface DockValidForm extends DockForm {
  length: number;
  width: number;
  taxRate: number;
  defaultPrice: number;
  outOfSeasonTimeFrom: string;
  outOfSeasonTimeTo: string;
  restaurants: Array<{
    type: string;
    name: string;
    url?: string;
    distance?: number | null;
  }>;
  addons: Array<{
    addon: string;
    id: string;
    description?: string;
    price: string;
    priceType: string;
    tax?: string | null;
    taxType?: string | null;
    taxable: boolean;
  }>;
}

export function parseFormDataFromApi(dockFromApi: DockFromApi): DockValidForm {
  const {
    operationalHours,
    image,
    outOfSeasonTimeFrom,
    outOfSeasonTimeTo,
    restaurants,
    addons,
    address,
    city,
    cityObject,
    length,
    width,
    name,
    description,
    phone,
    email,
    state,
    zipCode,
    sections,
    faqs,
    taxRate,
    defaultPrice,
  } = dockFromApi;

  return {
    address,
    city: city || cityObject?.name || "",
    cityObject: cityObject?.["@id"] || "",
    state: state || "",
    length,
    width,
    name,
    description: description || "",
    phone,
    email,
    zipCode,
    taxRate,
    defaultPrice,
    days: convertHoursFromArrayToMap((operationalHours as any[]) ?? []),
    image: image?.["@id"] ?? "",
    outOfSeasonTimeFrom,
    outOfSeasonTimeTo,
    restaurants:
      restaurants?.map((restaurant) => ({
        type: restaurant.type ?? "",
        name: restaurant.name,
        url: restaurant.url ?? "",
        distance: restaurant.distance ?? null,
      })) ?? [],
    addons:
      addons?.map((addon) => ({
        id: addon["@id"] ?? "",
        addon: addon.addon["@id"] ?? "",
        description: addon.description ?? "",
        price: addon.price ?? "",
        priceType: addon.priceType ?? "",
        taxable: Boolean(addon.tax && addon.taxType),
        tax: addon.tax as string,
        taxType: addon.taxType as string,
      })) ?? [],
    sections: sections?.map((section) => ({
      ...section,
      spaces: section.spaces.map((space) => ({
        ...space,
        defaultPrice: parsePrice(space.defaultPrice),
        taxRate: parsePrice(space.taxRate),
      })),
    })),
    faqs,
  };
}

function parsePrice(priceString: string): number {
  return parseFloat(priceString);
}

export function formatFormDataToApi(dockFormData: DockValidForm): DockApiInput {
  const {
    days,
    image,
    outOfSeasonTimeFrom,
    outOfSeasonTimeTo,
    restaurants,
    addons,
    address,
    city,
    cityObject,
    length,
    width,
    name,
    description,
    phone,
    email,
    state,
    zipCode,
    sections,
    faqs,
  } = dockFormData;

  return {
    taxRate: "0",
    defaultPrice: "0",
    operationalHours: convertHoursMapToArray(days),
    image,
    outOfSeasonTimeFrom: outOfSeasonTimeFrom
      ? convertDateToApi(outOfSeasonTimeFrom)
      : void 0,
    outOfSeasonTimeTo: outOfSeasonTimeTo
      ? convertDateToApi(outOfSeasonTimeTo)
      : void 0,
    restaurants: restaurants
      .filter((restaurant) => restaurant.name)
      .map((restaurant) => ({
        ...restaurant,
        distance: parseFloat(restaurant.distance + ""),
      })),
    // @ts-ignore
    addons: addons
      .filter((addon) => addon.price)
      .map((addon) => {
        return {
          id: addon.id,
          addon: addon.addon,
          description: addon.description,
          price: addon.price.toString(),
          priceType: addon.priceType,
          tax: addon.taxable ? addon.tax?.toString() : null,
          taxType: addon.taxable ? addon.taxType : null,
        };
      }),
    sections: sections?.map((s) => ({
      ...s,
      id: s["@id"],
      image: (() => {
        // return s?.image ?? undefined;
        if (s.image) {
          return s.image?.["@id"] ?? s.image;
        }
        return undefined;
      })(),
      spaces: s.spaces?.map((space) => ({
        ...space,
        id: space["@id"],
        defaultPrice: space.defaultPrice?.toString(),
        taxRate: space.taxRate?.toString(),
        prices: space.prices?.map((price) => ({
          dimensionFrom: price.dimensionFrom?.toString(),
          dimensionTo: price.dimensionTo?.toString(),
          price: price.price?.toString(),
        })),
      })),
    })),
    address,
    city,
    cityObject,
    length,
    width,
    name,
    description,
    phone,
    email,
    state,
    zipCode,
    faqs: faqs.map((f, i) => ({
      id: f["@id"],
      answer: f.answer,
      question: f.question,
      sorder: i,
    })),
  };
}
