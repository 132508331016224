import { createGate } from "effector-react";

import { createFieldArray, createForm } from "effector-react-form";
import { attach, createEffect, createStore, sample } from "effector";

import {
  fromApi,
  getCityCollection,
  getListFromApi,
  postDockCollection,
} from "shared/api";
import { createValidator } from "shared/lib/form";

import { City } from "shared/api/types";

import { imageUploaded } from "../upload-dock-image.model";
import { dockFormSchema } from "./form";
import {
  DockApiInput,
  DockForm,
  formatFormDataToApi,
} from "../lib/form-parse-format";

export const formGate = createGate();

export const $cities = createStore<City[]>([]);

export const addDockForm = createForm<DockForm>({
  initialValues: {
    image: "",
    name: "",
    description: "",
    address: "",
    city: "",
    cityObject: "",
    state: "",
    zipCode: "",
    phone: "",
    email: "",
    length: null,
    width: null,
    days: {},
    outOfSeasonTimeFrom: "",
    outOfSeasonTimeTo: "",
    restaurants: [],
    addons: [],
    sections: [
      {
        name: "",
        spaces: [{}],
      },
    ],
    faqs: [],
  },
  validate: createValidator(dockFormSchema),
  // @ts-ignore
  mapSubmit: ({ values }) => formatFormDataToApi(values),
  onSubmit: (values) => createNewDockFx(values as any),
});

addDockForm.$values.watch((value) => console.log("addDockForm.$values", value));

export const fieldArray = createFieldArray({
  form: addDockForm,
});

const createNewDockFx = attach({
  effect: createEffect(fromApi(postDockCollection)),
  mapParams: (body: DockApiInput) => ({ body }),
});

export const $createdDock = createStore<DockApiInput | null>(null)
  .on(createNewDockFx.done, (_, { params }) => params)
  .reset(formGate.close);

const getCitiesFx = attach({
  // @ts-ignore
  effect: getListFromApi(getCityCollection),
});

$cities.on(getCitiesFx.doneData, (_, { items }) => items);

sample({
  clock: formGate.open,
  target: getCitiesFx,
});

sample({
  clock: imageUploaded,
  fn: (value) => ({ field: "image", value }),
  target: addDockForm.setValue,
});

export const $isAddDockFormSubmitting = createNewDockFx.pending;
