import { createForm } from "effector-react-form";
import {
  combine,
  Effect,
  attach,
  sample,
  createStore,
  Store,
  createEvent,
  createEffect,
} from "effector";
import { and, either, every, or } from "patronum";
import { number, object, string } from "yup";

import { createValidator, formField, formFieldChanged } from "shared/lib/form";
import { requiredFieldValidationError } from "shared/config/error-text";
import {
  createReservationsDateTimeModel,
  getReservationWithClientPriceFx,
  ReservationPrice,
} from "entities/reservations";
import { $docksList } from "entities/docks";
import { Boat, Client } from "shared/api/types";
import { createOpenAbleState } from "shared/lib/effector-openable-state";
import { createGate } from "effector-react";
import { Space } from "entities/docks/types";
import { apiRequestFx } from "shared/api";

type PriceInfo = ReservationPrice;
export const $priceInfo = createStore<PriceInfo | null>(null);

const numberSchema = number()
  .required(requiredFieldValidationError)
  .nullable()
  .typeError(" ");

export const managerReservationValidator = createValidator(
  object({
    dock: string().required(requiredFieldValidationError).typeError(" "),
    loa: numberSchema.min(0, " ").max(100, "LOA must be 100 or less."),
    beam: numberSchema.min(0, " ").max(100, "BEAM must be 100 or less."),
    date: string().required(requiredFieldValidationError).typeError(" "),
    time: object().required(requiredFieldValidationError).typeError(" "),
    duration: numberSchema,
    comment: string(),
  })
);

interface FormParams {
  onSubmit: Effect<any, any>;
  getAvailableItemsFx: Effect<
    { hours: number; monthStartDate: string },
    Record<string, { from: string; to: string }[]>
  >;
  $client: Store<Client | null>;
  $boat: Store<Boat | null>;
  $addons: Store<string[] | null>;
  $isCharter: Store<boolean | null>;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createManagerReservationForm(params: FormParams) {
  const reservationForm = createForm<{
    loa: number | null;
    beam: number | null;
    dock: string;
    date: string;
    time: {
      from: string;
      to: string;
    } | null;
    duration: number | null;
    isCharter: boolean;
    comment: string;
    client: string | null;
    boat: string | null;
    isSuperOverride: boolean;
    dockAddons: string[];
    dockSpace: string;
  }>({
    validate: managerReservationValidator,
    initialValues: {
      loa: null,
      beam: null,
      dock: "",
      date: "",
      time: null,
      duration: null,
      isCharter: false,
      comment: "",
      client: null,
      boat: null,
      isSuperOverride: false,
      dockAddons: [],
      dockSpace: "",
    },
    onSubmit: async () => {
      await formSubmitFx();
    },
  });

  const formGate = createGate(reservationForm);
  const boatSelected = createEvent();
  const superOverrideNotConfirmed = createEvent();
  const $isLOADisabled = createStore(false);

  const [loaChangeModal, loaChangeModalActions] = createOpenAbleState();
  const [superOverrideModal, superOverrideModalActions] = createOpenAbleState();

  const $duration = formField(reservationForm, "duration");
  const $selectedDate = formField(reservationForm, "date");
  const $selectedTime = formField(reservationForm, "time");
  const $loa = formField(reservationForm, "loa");
  const $beam = formField(reservationForm, "beam");
  const $dock = formField(reservationForm, "dock");
  const $client = formField(reservationForm, "client");
  const $boat = formField(reservationForm, "boat");
  const $isCharter = formField(reservationForm, "isCharter");
  const $comment = formField(reservationForm, "comment");
  const $isSuperOverride = formField(reservationForm, "isSuperOverride");
  const $dockAddons = formField(reservationForm, "dockAddons");
  const $availableSpaceId = formField(reservationForm, "dockSpace");

  const $dockSpace = $selectedTime.map((time) =>
    time?.dockSpaceId ? `/api/dock-spaces/${time?.dockSpaceId}` : null
  );

  const $dockSpaceId = combine(
    $isSuperOverride,
    $availableSpaceId,
    $dockSpace,
    (isOverride, availableSpace, selectedSpace) => {
      return isOverride ? `/api/dock-spaces/${availableSpace}` : selectedSpace;
    }
  );

  const $selectedDock = combine(
    $docksList,
    $dock,
    (docksList, id) => docksList.find((dock) => dock["@id"] === id) ?? null
  );

  const $boatInfo = combine({ loa: $loa ?? null, beam: $beam ?? null });

  const dateTimeModel = createReservationsDateTimeModel({
    $dock: $selectedDock,
    $boat: $boatInfo,
    $duration: $duration,
    $selectedDate,
    $selectedTime,
    $isSuperOverride,
    timeChanged: formFieldChanged(reservationForm, "time"),
    getAvailableItemsFx: params.getAvailableItemsFx,
    getAvailableItemsTrigger: params.onSubmit.fail,
  });

  const $priceRequestData = combine({
    dock: $dock,
    loa: $loa,
    beam: $beam,
    timeFrom: dateTimeModel.$timeFrom,
    timeTo: dateTimeModel.$timeTo,
    dockAddons: $dockAddons,
    isCharter: $isCharter,
    dockSpace: $dockSpaceId,
  });

  const $haveBoatInfo = or($boat, $boatInfo);

  const $isSubmitEnabled = every(
    [$dock, $client, $haveBoatInfo, $priceInfo],
    Boolean
  );

  const getPriceFx = attach({
    effect: getReservationWithClientPriceFx,
  });

  const loaChanged = sample({
    clock: params.$boat,
    source: $loa,
    filter: (currentLOA, boat) => !!currentLOA && boat?.loa !== currentLOA,
  });

  sample({
    clock: $loa,
    source: reservationForm.$values,
    fn: (values) => ({ ...values, date: null, time: null }),
    target: reservationForm.setValues,
  });

  sample({
    clock: $beam,
    source: reservationForm.$values,
    fn: (values) => ({ ...values, date: null, time: null }),
    target: reservationForm.setValues,
  });

  sample({
    clock: combine({ $dock, $loa, $beam, $duration }),
    target: dateTimeModel.$availableDates.reset(),
  });

  sample({
    clock: $priceRequestData,
    filter: and(
      $loa,
      $beam,
      dateTimeModel.$timeTo,
      dateTimeModel.$timeFrom,
      $dock,
      $dockSpaceId
    ),
    target: getPriceFx,
  });

  sample({
    source: params.$client,
    fn: (client) => ({ field: "client", value: client["@id"] }),
    target: reservationForm.setValue,
  });

  sample({
    source: params.$boat,
    fn: (boat) => ({ field: "boat", value: boat["@id"] }),
    target: reservationForm.setValue,
  });

  sample({
    source: params.$boat,
    fn: (boat) => ({ field: "loa", value: boat.loa }),
    target: reservationForm.setValue,
  });

  sample({
    source: params.$boat,
    fn: (boat) => ({ field: "beam", value: boat.beam }),
    target: reservationForm.setValue,
  });

  sample({
    source: params.$addons,
    fn: (addons) => ({ field: "dockAddons", value: addons }),
    target: reservationForm.setValue,
  });

  sample({
    source: params.$isCharter,
    fn: (isCharter) => ({ field: "isCharter", value: isCharter }),
    target: reservationForm.setValue,
  });

  sample({
    source: params.$boat.map(Boolean),
    target: boatSelected,
  });

  sample({
    clock: loaChanged,
    filter: and(formGate.status),
    target: loaChangeModalActions.open,
  });

  sample({
    source: $isSuperOverride,
    filter: $isSuperOverride,
    target: superOverrideModalActions.open,
  });

  sample({
    clock: superOverrideNotConfirmed,
    fn: () => ({ field: "isSuperOverride", value: false }),
    target: reservationForm.setValue,
  });

  sample({
    clock: superOverrideNotConfirmed,
    target: superOverrideModalActions.close,
  });

  $isLOADisabled.on(boatSelected, () => true);

  $priceInfo
    .reset([getPriceFx, formGate.close])
    .on(getPriceFx.doneData, (_, priceInfo) => priceInfo);

  /// Get Available Spaces for Dock
  // TODO: refactor
  const $dockId = $selectedDock.map((dock) => dock?.id);
  const $availableSpacesList = createStore<Space[]>([]);

  interface GetDockSpacesParams {
    dockId: number;
    boatId?: number;
    loa?: number;
    beam?: number;
    timeFrom: string;
    timeTo: string;
    reservationId?: number;
  }

  const getDockAvailableSpaces = createEffect(
    async (params: GetDockSpacesParams) => {
      const spaces = await apiRequestFx({
        method: "GET",
        path: "/api/reservation-schedule/spaces",
        query: params,
      });
      return spaces;
    }
  );

  const getAvailableSpacesFx = attach({
    // @ts-ignore
    effect: getDockAvailableSpaces,
    source: {
      timeFrom: dateTimeModel.$timeFrom,
      timeTo: dateTimeModel.$timeTo,
      loa: $loa,
      beam: $beam,
      dockId: $dockId,
      isSuperOverride: $isSuperOverride,
    },
    mapParams: (_, params) => params,
  });

  sample({
    clock: [
      dateTimeModel.$timeFrom,
      dateTimeModel.$timeTo,
      $loa,
      $beam,
      $dockId,
      $isSuperOverride,
    ],
    filter: and(
      dateTimeModel.$timeFrom,
      dateTimeModel.$timeTo,
      $loa,
      $beam,
      $dockId
    ),
    source: {
      timeFrom: dateTimeModel.$timeFrom,
      timeTo: dateTimeModel.$timeTo,
      loa: $loa,
      beam: $beam,
      dockId: $dockId,
      isSuperOverride: $isSuperOverride,
    },
    target: getAvailableSpacesFx,
  });

  $availableSpacesList.on(
    getDockAvailableSpaces.doneData,
    (_, spaces) => spaces
  );

  const formSubmitFx = attach({
    effect: params.onSubmit,
    source: {
      dock: $dock,
      client: $client,
      boat: $boat,
      timeFrom: dateTimeModel.$timeFrom,
      timeTo: dateTimeModel.$timeTo,
      comment: $comment,
      isCharter: $isCharter,
      isSuperOverride: $isSuperOverride,
      dockAddons: $dockAddons,
      dockSpace: $dockSpaceId,
    },
  });

  return {
    form: reservationForm,
    formGate,
    $isLOADisabled,
    loaChangeModal,
    loaChangeModalActions,
    superOverrideModal,
    superOverrideModalActions,
    superOverrideNotConfirmed,
    $isSubmitEnabled,
    $priceInfo,
    dateTimeModel,
    $selectedDock,
    $isSuperOverride,
    $availableSpacesList,
  };
}
