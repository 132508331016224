import styled from "styled-components";

import { InputField } from "shared/ui/ecosystems/forms";

import { ListField } from "shared/lib/form";

import { DockForm } from "@manager-app/features/manage-docks/lib/form-parse-format";

import { useState } from "react";
import { editDockForm } from "@manager-app/features/manage-docks/model/edit-dock-form.model";
import { useStore } from "effector-react";

import { useField } from "shared/lib/form/context";

import { ParkingForm } from "./parking-form";
import { SectionImageFieldComponent as SectionImageField } from "../image-field/section-image-field";
import { ListTemplate } from "../list-template";
import { ParkingDialog } from "./parking-dialog";

export function SectionForm(): JSX.Element {
  const form = useField({ name: "sections" });
  const sections = form.input?.value;

  const [activeSection, setActiveSection] = useState<null | number>(null);

  if (sections && sections.length >= 2) {
    return (
      <ListField name="sections">
        {({ map, remove, push }) => (
          <>
            <FauxHeader>
              <Title>Dock Section</Title>
              <CustomAddButton
                onClick={() => {
                  push({});
                  setActiveSection(sections.length);
                }}
              >
                Create New Dock Section
              </CustomAddButton>
            </FauxHeader>
            <ParkingDialog />
            {map(({ field, index }) => {
              if (index !== activeSection) {
                return (
                  <Section>
                    {field.image?.url ? (
                      <SectionImage src={field.image.url} />
                    ) : (
                      <SectionNoImage />
                    )}
                    <div>
                      <SectionHeader>Dock Section {index + 1}</SectionHeader>
                      <SectionItem>
                        <SectionTitle>Dock section name: </SectionTitle>
                        <SectionDescription>{field.name}</SectionDescription>
                      </SectionItem>
                      <SectionItem>
                        <SectionTitle>Slips: </SectionTitle>
                        <SectionDescription>
                          {field.spaces?.length ?? 0}
                        </SectionDescription>
                      </SectionItem>
                    </div>
                    <ChangeButton
                      onClick={() => {
                        setActiveSection(index);
                      }}
                    >
                      Change
                    </ChangeButton>
                  </Section>
                );
              } else {
                return (
                  <SingleSectionRoot>
                    <Image>
                      <SectionImageField
                        fieldName={`sections[${index}].image`}
                      />
                    </Image>

                    <Content>
                      <ItemHeader>
                        <ItemTitle>Dock Section Information</ItemTitle>
                        <ChangeButton onClick={() => remove(index)}>
                          Delete section
                        </ChangeButton>
                      </ItemHeader>

                      <FieldsGroup>
                        <InputField
                          name="name"
                          label="Enter Dock Section name *"
                        />
                      </FieldsGroup>

                      <ItemHeader>
                        <ItemTitle>Docking Spaces</ItemTitle>
                      </ItemHeader>

                      <ParkingForm idx={index} sectiondId={field.id} />
                    </Content>
                  </SingleSectionRoot>
                );
              }
            })}
          </>
        )}
      </ListField>
    );
  }

  return (
    <div id="dockContainer">
      <ListField name="sections">
        {({ map, remove, push }) => (
          <>
            <FauxHeader>
              <Title>Dock Section</Title>
              <CustomAddButton
                onClick={() => {
                  push({});
                  const el = document.getElementById("dockContainer");
                  setTimeout(() => {
                    if (el && sections.length >= 1) {
                      el.scrollIntoView({ block: "end", behavior: "smooth" });
                    }
                  }, 0);
                }}
              >
                Create New Dock Section
              </CustomAddButton>
            </FauxHeader>
            <ParkingDialog />
            <ListTemplate<DockForm["sections"][0]>
              map={map}
              onDelete={remove}
              onAdd={() => push({})}
              addLabel=""
              deleteLabel="Delete Section"
              getItemTitle={(_, index) => `Dock Section ${index + 1}`}
              renderItem={(field, index) => (
                <SectionRoot>
                  <Image>
                    <SectionImageField fieldName={`sections[${index}].image`} />
                  </Image>

                  <Content>
                    <ItemHeader>
                      <ItemTitle>Dock Section Information</ItemTitle>
                    </ItemHeader>

                    <FieldsGroup>
                      <InputField
                        name="name"
                        label="Enter Dock Section name *"
                      />
                    </FieldsGroup>

                    <ItemHeader>
                      <ItemTitle>Docking Spaces</ItemTitle>
                    </ItemHeader>

                    <ParkingForm idx={index} sectiondId={field.id} />
                  </Content>
                </SectionRoot>
              )}
            />
          </>
        )}
      </ListField>
    </div>
  );
}

const Section = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  gap: 12px;
  border-bottom: 1px solid gray;
  &:last-child {
    border-bottom: none;
  }
`;

const SectionItem = styled.div``;
const SectionHeader = styled.div`
  font-weight: bold;
  color: #2d4fa1;
  margin-bottom: 6px;
`;
const SectionTitle = styled.span`
  color: gray;
`;
const SectionDescription = styled.span`
  color: black;
  font-weight: bold;
`;

const SectionImage = styled.img`
  width: 78px;
  height: 78px;
  background-color: gray;
  border-radius: 10px;
`;

const SectionNoImage = styled.img`
  width: 78px;
  height: 78px;
  background-color: white;
  outline: 0.5px dashed #2d4fa1;
  border-radius: 10px;
`;

const CustomAddButton = styled.div.attrs({
  role: "button",
})`
  line-height: 26px;
  cursor: pointer;
  width: 200px;
  height: 38px;
  border-width: 0.5px;
  border-color: #2d4fa1;
  color: #2d4fa1;
  border-style: solid;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #0a1128;
`;

const FauxHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const ChangeButton = styled.div.attrs({
  role: "button",
})`
  font-size: 16px;
  line-height: 26px;
  text-decoration-line: underline;
  color: #2d4fa1;
  cursor: pointer;
  margin-left: auto;
`;

const SectionRoot = styled.div`
  display: grid;
  grid-template-columns: 300px auto;
  gap: 16px;
  padding-bottom: 24px;
  min-height: 360px;
`;

const SingleSectionRoot = styled.div`
  padding-top: 24px;
  display: grid;
  grid-template-columns: 300px auto;
  gap: 16px;
  padding-bottom: 12px;
  margin-botton: 12px;
  min-height: 360px;

  border-bottom: 1px solid gray;
  &:last-child {
    border-bottom: none;
  }
`;

const Image = styled.div`
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
`;

const ItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const FieldsGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  margin-bottom: 24px;
`;

const ItemTitle = styled.div`
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
`;
