import React from "react";
import styled from "styled-components";
import { MapFieldArrayCallback } from "effector-react-form/types/ts";

import { TrashIcon } from "shared/ui/icons";
import { StyledComponent } from "@emotion/styled";

export function ListTemplate<T>(props: {
  map: (fn: MapFieldArrayCallback) => React.ReactNode[];
  onDelete(index: number): void;
  onAdd(): void;
  renderItem(item: T, index: number): React.ReactNode;
  getItemTitle(item: T, index: number): React.ReactNode;
  addLabel: string;
  deleteLabel?: string;
  addButton?: StyledComponent<"div", any>;
  hideAddButton?: boolean;
  hideDeleteButton?: boolean;
}): JSX.Element {
  return (
    <ListTemplateRoot>
      <List>
        {props.map(({ index, field }) => (
          <ListItem key={index}>
            <ItemHeader>
              <ItemTitle>{props.getItemTitle(field, index)}</ItemTitle>
              {props.hideDeleteButton ? null : (
                <DeleteButton onClick={() => props.onDelete(index)}>
                  <DeleteIcon /> {props.deleteLabel ?? "Delete"}
                </DeleteButton>
              )}
            </ItemHeader>

            <ItemContent>{props.renderItem(field, index)}</ItemContent>
          </ListItem>
        ))}
      </List>
      {props.hideAddButton ? null : (
        <AddButton onClick={props.onAdd}>{props.addLabel}</AddButton>
      )}
    </ListTemplateRoot>
  );
}

const ItemTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5e88ef;
`;

const DeleteIcon = styled(TrashIcon)`
  width: 16px;
  height: 16px;
`;

const DeleteButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #2d4fa1;

  ${DeleteIcon} {
    margin-right: 4px;
  }
`;

const ItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ItemContent = styled.div``;

const ListItem = styled.div`
  ${ItemHeader} {
    margin-bottom: 8px;
  }
`;

const List = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
`;

const AddButton = styled.div.attrs({
  role: "button",
})`
  font-size: 16px;
  line-height: 26px;
  text-decoration-line: underline;
  color: #2d4fa1;
  cursor: pointer;
`;

const ListTemplateRoot = styled.div`
  ${List} {
    margin-bottom: 12px;
  }
`;
