import styled from "styled-components";
import { TrashIcon } from "shared/ui/icons";
import {
  FtInputField,
  InputField,
  PriceField,
  TaxField,
} from "shared/ui/ecosystems/forms";
import { mediaPhone } from "shared/ui/styles/media";
import { ListField } from "shared/lib/form";
import { ButtonGroupField } from "shared/ui/ecosystems/forms/organisms/button-group-field";

import {
  ActionCopy,
  ActionDelete,
  ActionEdit,
  Table,
  TableActions,
} from "shared/ui/table";

import { editDockForm } from "@manager-app/features/manage-docks/model/edit-dock-form.model";
import { useStore } from "effector-react";
import { spaceEditingStarted } from "@manager-app/features/manage-docks/model/parking-dialog.model";
import { useContext } from "react";
import { FieldArrayContext, useField } from "shared/lib/form/context";
import { useFieldArray } from "effector-react-form";

import { DockSpace } from "@manager-app/features/manage-docks/lib/form-parse-format";

import { ListTemplate } from "../list-template";

interface Props {
  idx: number;
  sectiondId: number;
}

export function ParkingForm({ idx }: Props): JSX.Element {
  const form = useField({ name: `spaces` });
  const slips: DockSpace[] = form.input?.value ?? [];

  const fieldArray = useContext(FieldArrayContext);
  if (fieldArray === null) {
    throw Error(`Can't find FieldArrayContext value`);
  }
  const fieldArrayParams = useFieldArray({
    name: `sections.${idx}.spaces`,
    fieldArray,
  });

  const containerName = `parkingContainer${idx}`;
  const scrollToBotom = () => {
    const el = document.getElementById(containerName);
    if (!el) {
      return;
    }

    setTimeout(() => {
      el.scrollIntoView({ block: "end", behavior: "smooth" });
    }, 100);
  };

  if (slips?.length >= 2) {
    return (
      <Content id={containerName}>
        {slips && (
          <>
            <CustomAddButton
              onClick={() => {
                fieldArrayParams.push({});
                spaceEditingStarted({
                  sectionIndex: idx,
                  spaceIndex: slips.length,
                });
                scrollToBotom();
              }}
            >
              Create Docking Space
            </CustomAddButton>
            <Table
              columns={[
                {
                  header: "#",
                  renderCell: (_, index) => index,
                },
                {
                  header: "Name",
                  renderCell: (slip) => slip.name,
                },
                {
                  header: "Docking",
                  renderCell: (slip) =>
                    slip.parkingType
                      ? slip.parkingType[0].toUpperCase() +
                        slip.parkingType.substring(1)
                      : "",
                },
                {
                  header: "Length",
                  renderCell: (slip) => `${slip.length} ft`,
                },
                {
                  header: "Beam",
                  renderCell: (slip) => `${slip.width} ft`,
                },
                {
                  header: "$ Price",
                  renderCell: (slip) => `$${slip.defaultPrice}`,
                },
                {
                  header: "Edit",
                  align: "right",
                  renderCell: (slip, index) => (
                    <>
                      <TableActions>
                        <ActionEdit
                          onClick={() => {
                            spaceEditingStarted({
                              sectionIndex: idx,
                              spaceIndex: index,
                            });
                          }}
                        />
                        <ActionCopy
                          onClick={() => {
                            fieldArray.push({
                              fieldName: `sections.${idx}.spaces`,
                              value: {
                                ...slip,
                                id: undefined,
                                ["@id"]: undefined,
                              },
                            });
                          }}
                        />
                        <ActionDelete
                          onClick={() => {
                            fieldArray.remove({
                              fieldName: `sections.${idx}.spaces`,
                              index,
                            });
                          }}
                        />
                      </TableActions>
                    </>
                  ),
                },
              ]}
              items={slips}
            />
          </>
        )}
      </Content>
    );
  }

  return (
    <Content id={containerName}>
      <ListField name={`sections.${idx}.spaces`}>
        {({ map, remove, push }) => (
          <>
            <ListTemplate<DockSpace>
              map={map}
              onDelete={remove}
              onAdd={() => {
                push({});
                scrollToBotom();
              }}
              deleteLabel="Delete Space"
              addLabel=""
              hideAddButton
              getItemTitle={(_, index) => `Docking Space ${index + 1} *`}
              renderItem={(item, jdx) => (
                <>
                  <SubBlock>
                    <Subtitle>Basic Info</Subtitle>
                    <FieldsGroup>
                      <InputField
                        name="name"
                        label="Enter docking space name *"
                      />
                    </FieldsGroup>
                  </SubBlock>

                  <SubBlock>
                    <Subtitle>Type of Docking *</Subtitle>
                    <ButtonGroupField
                      name="parkingType"
                      options={[
                        {
                          label: "Docking Along",
                          value: "along",
                        },
                        {
                          label: "Docking Across",
                          value: "across",
                        },
                      ]}
                    ></ButtonGroupField>

                    <HalfGroup>
                      <FtInputField name="length" label="Enter Length *" />
                      <FtInputField name="width" label="Enter Beam *" />
                    </HalfGroup>
                  </SubBlock>

                  <SubBlock>
                    <ItemHeader>
                      <Subtitle>Price Settings</Subtitle>
                    </ItemHeader>

                    <HalfGroup>
                      <PriceField
                        name="defaultPrice"
                        label="Default Price *"
                        min={0}
                      />
                      <TaxField
                        name="taxRate"
                        label="Tax Rate *"
                        maxLength={5}
                      />
                    </HalfGroup>
                  </SubBlock>

                  <ListField name={`sections.${idx}.spaces.${jdx}.prices`}>
                    {({ map, remove, push }) => (
                      <ListTemplate
                        map={map}
                        onDelete={remove}
                        onAdd={() => push({})}
                        addLabel="Add Additional Price"
                        deleteLabel="Delete Price"
                        getItemTitle={(_, index) => `Price ${index + 1} *`}
                        renderItem={() => (
                          <ThirdGroup>
                            <FtInputField
                              name="dimensionFrom"
                              label="From"
                              min={0}
                            />
                            <FtInputField
                              name="dimensionTo"
                              label="To"
                              min={0}
                            />
                            <PriceField name="price" label="Price" min={0} />
                          </ThirdGroup>
                        )}
                      />
                    )}
                  </ListField>
                  <ButtonVariant
                    onClick={() => {
                      fieldArray.push({
                        fieldName: `sections.${idx}.spaces`,
                        value: {
                          ...item,
                          id: undefined,
                          ["@id"]: undefined,
                        },
                      });
                    }}
                  >
                    Duplicate
                  </ButtonVariant>
                </>
              )}
            />
            <CustomAddButton
              onClick={() => {
                push({});
                if (slips.length >= 1) {
                  spaceEditingStarted({
                    sectionIndex: idx,
                    spaceIndex: slips.length,
                  });
                  scrollToBotom();
                }
              }}
            >
              Create Docking Space
            </CustomAddButton>
          </>
        )}
      </ListField>
    </Content>
  );
}

const CustomAddButton = styled.div.attrs({
  role: "button",
})`
  line-height: 26px;
  cursor: pointer;
  width: 200px;
  height: 38px;
  border-width: 0.5px;
  border-color: #2d4fa1;
  color: #2d4fa1;
  border-style: solid;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;

const HalfGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  ${mediaPhone} {
    grid-template-columns: 1fr;
  }
`;

const ThirdGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;

  ${mediaPhone} {
    grid-template-columns: 1fr;
  }
`;

const ButtonVariant = styled.div.attrs({
  role: "button",
})`
  font-size: 16px;
  line-height: 26px;
  text-decoration-line: underline;
  color: #2d4fa1;
  cursor: pointer;
  margin-top: 24px;
`;

const Content = styled.div`
  width: 100%;

  .wideModal {
    outline: 1px solid red;
  }

  .wideModal .MuiPaper {
    outline: 1px solid red;
  }
`;

const Subtitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
`;

const SubBlock = styled.div`
  margin-bottom: 24px;
`;

const ItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const FieldsGroup = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr;

  ${mediaPhone} {
    grid-template-columns: 1fr;
  }

  ${HalfGroup} {
    grid-column: 1 / 2;
    ${mediaPhone} {
      grid-column: 1;
    }
  }

  ${ThirdGroup} {
    grid-column: 1 / 3;
    ${mediaPhone} {
      grid-column: 1;
    }
  }
`;

const ItemTitle = styled.div`
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #5e88ef;
`;

const DeleteIcon = styled(TrashIcon)`
  width: 16px;
  height: 16px;
`;

const DeleteButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #2d4fa1;

  ${DeleteIcon} {
    margin-right: 4px;
  }
`;
