import React, { Ref, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { useStore } from "effector-react";

import {
  FormInlineError,
  InputLabel,
  SubmitButton,
} from "shared/ui/ecosystems/forms";
import { mediaPhone } from "shared/ui/styles/media";
import { useField, useImplicitField } from "shared/lib/form";

import { http } from "shared/api";

import { useFormContext } from "shared/lib/form/context";

import { ReactComponent as UploadIconC } from "./upload.svg";
import { ReactComponent as AddIcon } from "./add-image.svg";
import {
  largeImageSelected,
  $selectedImageError,
} from "../../../upload-section-image.model";

interface Props {
  className?: string;
  fieldName: string;
}

const maxMBSize = 2;

export function SectionImageFieldComponent({
  className,
  fieldName,
}: Props): JSX.Element {
  const { input, isShowError } = useField({ name: "image" });
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
  const imageError = useStore($selectedImageError);
  const [image, setImage] = useState<{ url: string; id: string }>(input.value);

  const form = useFormContext();

  const selectImage = async (file: Blob) => {
    const formData = new FormData();
    formData.append("file", file);
    setIsImageUploading(true);
    const { data } = await http.post("/api/dock-section-images", formData);
    setIsImageUploading(false);

    form.setValue({
      field: fieldName,
      // value: `/api/dock-section-images/${data.id}`,
      value: data["@id"],
    });
    setImage({ url: data.url, id: data.id });
  };

  const inputRef = useRef<HTMLInputElement>(null);

  function handleButtonClick() {
    // @ts-ignore
    inputRef.current.click();
  }

  return (
    <ImageFieldRoot className={className}>
      <HiddenFileInput
        disabled={isImageUploading}
        inputRef={inputRef}
        selectImage={selectImage}
      >
        {image && !isImageUploading ? (
          <UploadedImageRoot>
            <UploadedImage src={image.url} alt="dock-image" />
            <UploadButton
              isSubmitting={isImageUploading}
              onClick={handleButtonClick}
            >
              <AddImageIcon as={AddIcon} />
              Change Image
            </UploadButton>
          </UploadedImageRoot>
        ) : (
          <UploadNewImage invalid={isShowError}>
            <div>
              <UploadIcon as={UploadIconC} />
              <Formats>Files supported: JPG, PNG</Formats>
            </div>
            <div>
              <UploadButton
                isSubmitting={isImageUploading}
                onClick={handleButtonClick}
              >
                <AddImageIcon as={AddIcon} />
                Browse files
              </UploadButton>
              <SizeInfo>Maximum size {maxMBSize}Mb</SizeInfo>
              <SizeInfo>1180*480, horizontal</SizeInfo>
            </div>
          </UploadNewImage>
        )}
      </HiddenFileInput>

      <FormInlineError>{imageError}</FormInlineError>
    </ImageFieldRoot>
  );
}

const UploadIcon = styled.div`
  width: 88px;
  height: 64px;
`;

const Formats = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #0a1128;
`;

const AddImageIcon = styled.div`
  width: 24px;
  height: 24px;
`;

const UploadButton = styled(SubmitButton).attrs({
  type: "button",
})`
  width: 180px;
  ${AddImageIcon} {
    margin-right: 10px;
  }
`;

const SizeInfo = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #969899;
`;

const UploadedImage = styled.img`
  width: 100%;
  border-radius: 16px;
`;

const UploadedImageRoot = styled.div`
  position: relative;

  ${UploadButton} {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translatex(-50%);
  }
`;

const UploadNewImage = styled.div<{ invalid?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 360px;
  width: 100%;
  cursor: pointer;
  padding: 48px 0 40px;
  background: #f7f9fc;
  border: 1px dashed #2F3895;
  border-radius: 16px;

  ${(props) =>
    props.invalid &&
    css`
      border-style: solid;
      border-color: #de2016;
    `}}

  & > * {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ${UploadIcon} {
    margin-bottom: 20px;
  }

  ${UploadButton} {
    margin-bottom: 8px;
  }

  ${mediaPhone} {
    height: 281px;
    padding: 32px 24px;
  }
`;

const ImageFieldRoot = styled.div`
  ${InputLabel} {
    margin-bottom: 10px;

    ${mediaPhone} {
      margin-bottom: 8px;
    }
  }
`;

function HiddenFileInput(props: {
  children: React.ReactNode;
  inputRef: Ref<HTMLInputElement>;
  disabled?: boolean;
  selectImage: (file: Blob) => void;
}) {
  function handleFileSelect(event: React.FormEvent<HTMLInputElement>) {
    if (event.currentTarget?.files?.[0]) {
      const file = event.currentTarget?.files?.[0];
      if (file.size <= maxMBSize * 1024 * 1024) {
        props.selectImage(file);
        // imageSelected(file);
      } else {
        largeImageSelected();
      }
    }
  }

  return (
    <HiddenFileInputRoot>
      <input
        disabled={props.disabled}
        ref={props.inputRef}
        type="file"
        accept="image/jpeg, image/jpg, image/png"
        onChange={handleFileSelect}
      />
      {props.children}
    </HiddenFileInputRoot>
  );
}

const HiddenFileInputRoot = styled.label`
  display: block;
  input[type="file"] {
    width: 0;
    height: 0;
    position: absolute;
  }
`;
